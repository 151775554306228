<template>
  <div id="main">
    <div class="app-container">
      <div class="icon-container">
        <img src="./anim.gif" width="200"/>
      </div>
      <div class="task-counter">
        <button @click="closeTMA">
          Закрыть сканер QR-кодов
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="var(--tg-theme-accent-text-color)"
              class="bi bi-x-square-fill"
              viewBox="0 -3 16 16"
          >
            <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      f: 0
    }
  },
  mounted() {
    this.TMA.ready()
    this.TMA.expand()
    this.TMA.MainButton.setText('Открыть сканер')
    this.TMA.MainButton.show()
    this.TMA.onEvent('qrTextReceived', this.processQRCode)
    this.TMA.onEvent('mainButtonClicked', this.mainButtonClicked)
    this.showQRScanner()
  },
  methods: {
    showQRScanner() {
      console.log('showQRScanner')
      this.TMA.showScanQrPopup({
        text: 'Point to the QR code'
      })
    },
    processQRCode(data) {
      if (data.data.length > 1024) {
        this.TMA.sendData('Error')
        return
      }
      this.TMA.HapticFeedback.impactOccurred('rigid')
      this.TMA.HapticFeedback.impactOccurred('heavy')
      this.TMA.closeScanQrPopup()
      this.TMA.sendData(JSON.stringify(data))
      this.TMA.close()
    },
    mainButtonClicked() {
      this.showQRScanner()
    },
    closeTMA() {
      this.TMA.close()
    }
  }
}
</script>

<style>
#main {
  background-color: var(--tg-theme-bg-color, white);
  color: var(--tg-theme-text-color, black);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.app-container {
  text-align: center;
  color: var(--tg-theme-text-color, black);
  font-family: Arial, sans-serif;
}

.icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.task-counter {
  display: flex;
  justify-content: center;
}

button {
  font-family: Arial, sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: var(--tg-theme-header-bg-color);
  color: var(--tg-theme-text-color);
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  user-select: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(2px);
}
</style>
